<template>
  <div class="mx-4 mt-3">
      <div class="form-container">
          <div class="row justify-content-center">
            <div class="col-9">
              <form @submit.prevent="handleSubmit">
                <div class="row row-sm text-left">
                  <div class="col-12 text-center">
                    <button :disabled="loading" type="submit" class="btn btn-primary-red-10" >{{ $t('profile.change-teams-password.change') }}</button>
                  </div>
                </div>
              </form>
            </div>

          </div>
      </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'change-teams-password',

    data() {
      return {
        loading: false
      }
    },

    methods: {
      changeTeamsPassword() {
        this.loading = true;
        axios.post('/api/profile/change-teams-password')
          .then(response => {
            const data = response.data.data;
            this.helpers.notifySuccessMessage(this.$t('profile.change-teams-password.password-changed',
              { email: data.email, password: data.password }));
          })
          .catch(error => {
            this.helpers.notifyErrorMessage(error);
          })
          .finally(() => {
            this.loading = false;
          })
      },

      handleSubmit() {
        this.$swal({
          title: this.$t('profile.change-teams-password.confirmation'),
          showCancelButton: true,
          confirmButtonText: this.$t('profile.change-teams-password.ok'),
          cancelButtonText: this.$t('profile.change-teams-password.cancel'),
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeTeamsPassword();
          }
        });
      }
    }
  };
</script>

<style scoped>

</style>
