<template>
  <div class="mx-4">
      <div class="form-container">
          <div class="form-header mb-4">
              <span>{{ $t('profile.change-password.title') }}</span>
          </div>
          <div class="row justify-content-center">
            <div class="col-9">
              <form @submit.prevent="handleSubmit">
                <div class="row row-sm text-left">
                  <div class="col-12">
                    <b-form-group
                      :label="$t('profile.change-password.current-password')"
                      label-for="current_password"
                      class="f-p2"
                    >
                      <b-form-input
                        @focus="delete errors.current_password"
                        id="current_password"
                        v-model="form.current_password"
                        type="password"
                        :class="errors.current_password ? 'is-invalid' : ''"
                        class="form-control"
                        :placeholder="$t('profile.change-password.current-password')"
                        required
                      ></b-form-input>
                      <div v-if="errors.current_password">
                        <span v-for="error of errors.current_password" class="d-block is-invalid">{{ error }}</span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      :label="$t('profile.change-password.new-password')"
                      label-for="new_password"
                      class="f-p2"
                    >
                      <b-form-input
                        @focus="delete errors.new_password"
                        id="new_password"
                        v-model="form.new_password"
                        type="password"
                        :class="errors.new_password ? 'is-invalid' : ''"
                        :placeholder="$t('profile.change-password.new-password')"
                        required
                      ></b-form-input>
                      <div v-if="errors.new_password">
                        <span v-for="error of errors.new_password" class="d-block is-invalid">{{ error }}</span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      :label="$t('profile.change-password.new-password-confirmation')"
                      label-for="new_password_confirmation"
                      class="f-p2"

                    >
                      <b-form-input
                        @focus="delete errors.new_password_confirmation"
                        id="new_password_confirmation"
                        v-model="form.new_password_confirmation"
                        type="password"
                        :class="errors.new_password_confirmation ? 'is-invalid' : ''"
                        :placeholder="$t('profile.change-password.new-password-confirmation')"
                        required
                      ></b-form-input>
                      <div v-if="errors.new_password_confirmation">
                        <span v-for="error of errors.new_password_confirmation" class="d-block is-invalid">{{ error }}</span>
                      </div>
                    </b-form-group>

                    <b-form-group class="text-center">
                      <button type="submit" class="btn btn-primary-red-10" >{{ $t('profile.change-password.change') }}</button>
                    </b-form-group>
                  </div>
                </div>
              </form>
            </div>

          </div>
      </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'change-password',

    data() {
      return {
        form: this.emptyForm(),
        errors: [],
      }
    },

    methods: {
      emptyForm() {
        return {
          current_password: '',
          new_password: '',
          new_password_confirmation: ''
        }
      },

      handleSubmit() {
        axios.post('/api/profile/change-password', this.form)
        .then(response => {

        
          this.helpers.notifySuccessMessage(this.$t('profile.change-password.password-changed'));
          this.form = this.emptyForm();
        })
        .catch(error => {
          this.helpers.notifyErrorMessage(error);

          if(error.response && error.response.data && error.response.data.message) {
            const message = error.response.data.message;
            if(message === 'validation_errors') this.errors = error.response.data.data;
          }
        })
      }
    }
  };
</script>

<style scoped>

</style>
