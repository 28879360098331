<template>
  <div class="mx-4">
    <div class="form-container">
      <div class="form-header mb-4">
        <span >{{ $t('profile.change-picture.title') }}</span>
      </div>
      <div class="row justify-content-center">
        <div class="col-9">
          <form @submit.prevent="handleSubmit">
            <div class="row row-sm">
              <div class="col-12 mt-3 mb-4">
                <div v-if="uploadedPicture" class="row mb-4" >
                  <div class="col-3">
                    <img :src="uploadedPicture" class="img-fluid"/>
                  </div>
                  <div class="col-9">
                    <p class="text-dark f-p2">{{ $t('profile.change-picture.wait-for-confirmation') }}</p>
                    <button
                      @click="deleteUploadedPicture"
                      class="btn btn-primary-red-7">
                      {{ $t('profile.change-picture.delete') }}
                    </button>
                  </div>

                </div>
              </div>
              <div class="col-12 col-md-8 col-lg-12 offset-md-2 offset-lg-0 mb-3">
                <b-form-group
                  :label="$t('profile.change-picture.choose-picture')"
                  label-for="picture"
                  class="f-p2"
                >
                  <b-form-file
                    v-model="form.picture"
                    @change="onFileInputChange"
                    :placeholder="$t('profile.change-picture.choose-picture')"
                    :drop-placeholder="$t('profile.change-picture.choose-picture')"
                  ></b-form-file>
                  <div v-if="errors.picture">
                    <span v-for="error of errors.picture" class="d-block is-invalid">{{ error }}</span>
                  </div>
                </b-form-group>

                <div v-if="form.picture" class="row">
                  <div class="col-3">
                    <img :src="pictureUrl"  class="img-fluid max-picture"/>
                  </div>
                  <div class="col-9">
                    <button
                      @click="form.picture = null"
                      class="btn btn-primary-red-7 mb-2 mt-3">
                      {{ $t('profile.change-picture.delete') }}
                    </button>
                  </div>
                </div>
                <div class="text-center mt-4">
                  <button type="submit"  class="btn btn-primary-red-10 mx-auto">{{ $t('profile.change-picture.change') }}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'change-picture',

    data() {
      return {
        form: this.emptyForm(),
        uploadedPicture: null,
        errors: [],
      }
    },

    mounted() {
      this.loadUploadedPicture();
    },

    computed: {
      pictureUrl() {
        return URL.createObjectURL(this.form.picture)
      }
    },

    methods: {
      deleteUploadedPicture() {
        axios.delete('/api/profile/uploaded-picture')
          .then(response => {
            this.helpers.notifySuccessMessage(this.$t('profile.change-picture.picture-deleted'))
            this.uploadedPicture = null;
          })
          .catch(error => {
            this.helpers.notifyErrorMessage(error)
          })
      },

      loadUploadedPicture() {
        axios.get('/api/profile/uploaded-picture')
          .then(response => {
            if(!response.data.error) this.uploadedPicture = `data:image/jpeg;base64,${response.data}`;
          })
      },

      emptyForm() {
        return {
          picture: null
        }
      },

      onFileInputChange() {
        delete this.errors.picture;
      },

      handleSubmit() {
        const formData = new FormData();
        formData.append('picture', this.form.picture);

        axios.post('/api/profile/change-picture', formData)
          .then(response => {
            this.helpers.notifySuccessMessage(this.$t('profile.change-picture.wait-for-confirmation'));
            this.loadUploadedPicture();
          })
          .catch(error => {
            this.helpers.notifyErrorMessage(error)
          })
          .finally(() => {
            this.form = this.emptyForm();
          })
      }
    }
  };
</script>

<style scoped>
  .max-picture{
    max-height: 8.5rem;
  }
</style>
