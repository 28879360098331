<template>
  <div class="mx-4 mt-5 mb-5">
       <div class="form-container">
            <div class="form-header mb-4">
              <span>{{ $t('profile.change-information.title') }}</span>
            </div>
            <div class="row justify-content-center">
                <div class="col-9">
                  <form @submit.prevent="handleSubmit" v-if="!helpers.isEmptyObject(contactInfo)">
                    <div class="row row-sm">
                      <div class="col-12 col-md-6">
                        <b-form-group
                          :label="$t('profile.change-information.mobile')"
                          label-for="mobile"
                          class="f-p2"
                        >
                          <b-form-input
                            @change="validateMobile($event)"
                            @focus="delete errors.mobile"
                            id="mobile"
                            v-model="form.mobile"
                            type="text"
                            :class="errors.mobile ? 'is-invalid' : ''"
                            required
                          ></b-form-input>
                          <div v-if="errors.mobile">
                            <span v-for="error of errors.mobile" class="d-block is-invalid">{{ error }}</span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-12 col-md-6">
                        <b-form-group
                          :label="$t('profile.change-information.phone')"
                          label-for="phone"
                          class="f-p2"
                        >
                          <b-form-input
                            @focus="delete errors.phone"
                            id="phone"
                            v-model="form.phone"
                            type="text"
                            :class="errors.phone ? 'is-invalid' : ''"
                          ></b-form-input>
                          <div v-if="errors.phone">
                            <span v-for="error of errors.phone" class="d-block is-invalid">{{ error }}</span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-12 col-md-6">
                        <b-form-group
                          :label="$t('profile.change-information.email')"
                          label-for="email"
                          class="f-p2"
                        >
                          <b-form-input
                            @focus="delete errors.email"
                            id="email"
                            v-model="form.email"
                            type="email"
                            :class="errors.email ? 'is-invalid' : ''"
                            required
                          ></b-form-input>
                          <div v-if="errors.email">
                            <span v-for="error of errors.email" class="d-block is-invalid">{{ error }}</span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-12 col-md-6">
                        <b-form-group
                          :label="$t('profile.change-information.ug_email')"
                          label-for="ug_email"
                          class="f-p2"
                        >
                          <b-form-input
                            type="text"
                            disabled
                            :value="form.email_ug"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-12 col-md-6">
                        <b-form-group
                          :label="$t('profile.change-information.address')"
                          label-for="address"
                          class="f-p2"
                        >
                          <b-form-input
                            @focus="delete errors.address"
                            id="address"
                            v-model="form.address"
                            type="text"
                            :class="errors.address ? 'is-invalid' : ''"
                            required
                          ></b-form-input>
                          <div v-if="errors.address">
                            <span v-for="error of errors.address" class="d-block is-invalid">{{ error }}</span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-12 col-md-6">
                        <b-form-group
                          :label="$t('profile.change-information.address_temp')"
                          label-for="address_temp"
                          class="f-p2"
                        >
                          <b-form-input
                            @focus="delete errors.address_temp"
                            id="address_temp"
                            v-model="form.address_temp"
                            type="text"
                            :class="errors.address_temp ? 'is-invalid' : ''"
                          ></b-form-input>
                          <div v-if="errors.address_temp">
                            <span v-for="error of errors.address_temp" class="d-block is-invalid">{{ error }}</span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-4 mt-4">
                        <b-form-group class="text-center">
                          <button class="btn btn-primary-red-10" type="submit" >{{ $t('profile.change-information.save') }}</button>
                        </b-form-group>
                      </div>
                    </div>
                  </form>
                  <sk-profile-contact-info class="w-100" v-else></sk-profile-contact-info>
                </div>
            </div>
       </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  import SkProfileContactInfo from '../skeletons/sk-profile-contact-info';

  export default {
    name: 'change-information',
    components: { SkProfileContactInfo },
    data() {
      return {
        form: {},
        errors: [],
      }
    },

    computed: {
        ...mapGetters({
          contactInfo: 'user/contactInfo'
        })
    },

    watch: {
        contactInfo(newVal, oldVal) {
          this.form = newVal;
        }
    },

    mounted() {
      this.$store.dispatch('user/loadContactInfo')
    },

    methods: {
      validateMobile(mobile) {
        this.form.mobile = this.form.mobile.replace(/\D/g, '');
      },

      async confirmMobile() {
        const { value: confirmationCode } = await this.$swal({
          input: 'text',
          inputLabel: this.$t('profile.change-information.confirm-mobile'),
          confirmButtonText: this.$t('profile.change-information.confirm')
        });

        if(confirmationCode) {
          this.form['mobile_confirmation'] = confirmationCode;
          this.handleSubmit();
        }
      },

      handleSubmit() {
        axios.post('/api/profile/contact-information', this.form)
        .then(response => {
          if(response.data.data.confirm_mobile) {
            this.confirmMobile();
          } else {
            this.helpers.notifySuccessMessage(this.$t('profile.change-information.successfully-updated'));
            this.$store.dispatch('user/loadContactInfo');
          }
        })
        .catch(error => {
          this.helpers.notifyErrorMessage(this.$t('errors.general'));

          delete this.form.mobile_confirmation;
          if(error.response && error.response.data && error.response.data.message) {
            const message = error.response.data.message;
            if(message === 'validation_errors') this.errors = error.response.data.data;
          }
        })
      }
    }
  };
</script>

<style scoped>

</style>
