<template>
  <skeleton theme="opacity" shape="radius" bg-color="#dcdbdc" class="p-3">
    <div class="row">
      <div class="col-12 col-md-6 mb-2">
        <tb-skeleton :aspect-ratio="0.15"></tb-skeleton>
      </div>
      <div class="col-12 col-md-6 mb-2">
        <tb-skeleton :aspect-ratio="0.15"></tb-skeleton>
      </div>
      <div class="col-12 col-md-6 mb-2">
        <tb-skeleton :aspect-ratio="0.15"></tb-skeleton>
      </div>
      <div class="col-12 col-md-6 mb-2">
        <tb-skeleton :aspect-ratio="0.15"></tb-skeleton>
      </div>
      <div class="col-12 col-md-6 mb-2">
        <tb-skeleton :aspect-ratio="0.15"></tb-skeleton>
      </div>
      <div class="col-12 col-md-6 mb-2">
        <tb-skeleton :aspect-ratio="0.15"></tb-skeleton>
      </div>
    </div>
  </skeleton>
</template>

<script>
  export default {
    name: 'sk-profile-contact-info',
  };
</script>
